import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://ba2da6bfe3fb0f45b28738224202251d@o55451.ingest.us.sentry.io/4507470303199232",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});